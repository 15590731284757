import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MarkerWithInfoWindow } from './MarkerWithInfoWindow';
import ReactTimeAgo from 'react-time-ago';

/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredMarkers = ({ locations, lastPosition }) => {
    const [markers, setMarkers] = useState({});
    const [selectedTreeid, setSelectedTreeid] = useState({ id: null, timestamp: 0 });

    // const selectedTree = useMemo(
    //     () =>
    //         trees && selectedTreeid
    //             ? trees.find(t => t.id === selectedTreeid)!
    //             : null,
    //     [trees, selectedTreeid]
    // );

    // create the markerClusterer once the map is available and update it when
    // the markers are changed
    const map = useMap();
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);

    useEffect(() => {
        if (!clusterer) return;

        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    // this callback will effectively get passsed as ref to the markers to keep
    // tracks of markers currently on the map
    const setMarkerRef = useCallback((marker, id) => {
        setMarkers(markers => {
            if ((marker && markers[id]) || (!marker && !markers[id]))
                return markers;

            if (marker) {
                return { ...markers, [id]: marker };
            } else {
                const { [id]: _, ...newMarkers } = markers;

                return newMarkers;
            }
        });
    }, []);

    const handleInfoWindowClose = useCallback(() => {
        setSelectedTreeid(null);
    }, []);

    const handleMarkerClick = useCallback((id) => {
        setSelectedTreeid(id);
    }, []);
    function addDotBeforeLastChar(str) {
        if (!str) return str;
        str = String(str);
        if (str.length < 2) { return str; } return str.slice(0, -1) + '.' + str.slice(-1);
    }
    return <>
        {locations.map(tree => (
            <MarkerWithInfoWindow
                id={tree.timestamp}
                key={tree.timestamp}
                position={{ lat: tree.lat, lng: tree.lon }}
                timestamp={tree.timestamp}
                status={tree.status}
                selected={(tree.lat === lastPosition?.lat && tree.lon === lastPosition?.lng)}
                onClick={handleMarkerClick}
                setMarkerRef={setMarkerRef}
            >
            </MarkerWithInfoWindow>
        ))}
        {selectedTreeid && (
            <InfoWindow anchor={markers[selectedTreeid.id]} onClose={() => setSelectedTreeid(false)}>
                <ReactTimeAgo date={new Date(selectedTreeid.timestamp * 1000)} locale={'pt'} /><br />
                <span>{addDotBeforeLastChar(selectedTreeid.status)}</span>V
            </InfoWindow >
        )
        }
    </>

};