import { useEffect, useState } from 'react';
import { getLocation } from '../services/location';
import { Map, AdvancedMarker, useMap, Pin } from '@vis.gl/react-google-maps';
import { useParams } from 'react-router-dom';
import { MarkerWithInfoWindow } from '../Components/MarkerWithInfoWindow';
import ReactTimeAgo from 'react-time-ago';
import { ClusteredMarkers } from '../Components/ClusteredMarker';
function TrackerMap() {
  const map = useMap('mainMap');

  const { id } = useParams();
  const [history, setHistory] = useState([]);
  const [lastPosition, setLastPosition] = useState({
    lat: 0, lng: 0
  });

  function filterOnly24(data) {
    const dateAgo = (((new Date()).getTime() - 86400000) / 1000).toFixed(0);

    return data.filter(item => {
      return item.timestamp >= dateAgo;
    });
  }


  async function loadHistory() {
    const locations = await getLocation(id);
    console.log(locations);
    setHistory(locations);
    // return locations;
    // const filtered = filterOnly24(locations);
    // setHistory(filtered);
  }
  useEffect(() => {
    if (!map) return;
    if (history.length > 0) {
      const { lat, lon } = history[history.length - 1];
      map.panTo({ lat, lng: lon });
      setLastPosition({ lat, lng: lon });
    }
  }, [map, history]);

  useEffect(() => {
    loadHistory();

  }, []);

  return (
    <Map
      style={{ width: '100vw', height: '100vh' }}
      defaultCenter={{ lat: 22.54992, lng: 0 }}
      defaultZoom={13}
      id="mainMap"
      gestureHandling={'greedy'}
      mapId={'425c84d26e949b3e'}
      disableDefaultUI={true}
    >
      <ClusteredMarkers locations={history} lastPosition={lastPosition} />
      {/* {history.map((item) => {
        return <MarkerWithInfoWindow
          position={{ lat: item.lat, lng: item.lon }}
          selected={(item.lat === lastPosition.lat && item.lon === lastPosition.lng)}>
          <div>
            <ReactTimeAgo date={new Date(item.timestamp * 1000)} locale={'pt'} />
          </div>
        </MarkerWithInfoWindow>
      })} */}
    </Map>
  );
}

export default TrackerMap;
