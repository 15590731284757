import { useEffect, useState } from 'react';
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps';
import TrackerMap from './Pages/TrackerMap';


function App() {


  return (
    <APIProvider apiKey={'AIzaSyAFbkDnYL2qb8ZrmhIGy87dFhfB_Dbonbc'}>
      <TrackerMap />
    </APIProvider>
  );
}

export default App;
