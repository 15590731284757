import { useCallback } from 'react';
import { AdvancedMarker, Pin, } from '@vis.gl/react-google-maps';

export const MarkerWithInfoWindow = ({ position, children, selected, setMarkerRef, id, onClick, timestamp, status }) => {
    const handleClick = useCallback(() => onClick({ id, timestamp, status }), [onClick, id]);
    const ref = useCallback(
        (marker) =>
            setMarkerRef(marker, id),
        [setMarkerRef, id]
    );
    return (
        <>
            <AdvancedMarker
                ref={ref}
                position={position}
                onClick={handleClick}
            >
                {selected ? <Pin
                    background={'#00b4d8'}
                    borderColor={'#0077b6'}
                    glyphColor={'#90e0ef'}
                /> : <Pin />}
            </AdvancedMarker>
        </>
    );
};

