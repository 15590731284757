import axios from "axios";


const api = axios.create({
    baseURL: process.env.REACT_APP_LOCAL ? 'https://pms.maplus.com.br/api/' : '/api/'
});


export async function getLocation(id) {
    try {
        const response = await api.get(id);
        return response.data;
    } catch (exception) {
        console.log(exception)
        return []
    }
}